export const pageSettingsCbgPalava = {
  landingPage: {
    title:
      "CBG Lodha World School - ICSE School in Dombivli| Excellence Within & Beyond",
    metaTags: [
      {
        name: "description",
        content:
          "CBG Lodha World School in Palava, Dombivli instills values & academic excellence in students, preparing them to be best citizens & leaders. Affiliated to ICSE, join us to pursue excellence.",
      },
      {
        property: "og:title",
        content:
          "CBG Lodha World School - ICSE School in Dombivli| Excellence Within & Beyond",
      },
      { property: "og:site_name", content: "LodhaWorldSchoolPalava" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/cbgpalava",
      },
      {
        property: "og:description",
        content:
          "CBG Lodha World School in Palava, Dombivli instills values & academic excellence in students, preparing them to be best citizens & leaders. Affiliated to ICSE, join us to pursue excellence.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairpersonCbg.jpg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/cbgpalava",
  },
  ourStory: {
    title: "Explore Lodha World School's Remarkable Journey since 2008",
    metaTags: [
      {
        name: "description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      {
        property: "og:title",
        content: "Explore Lodha World School's Remarkable Journey since 2008",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairperson.jpg",
      },
    ],
  },
  vision: {
    title: "Lodha World School - Vision & Mission",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us!",
      },
      {
        property: "og:title",
        content: "Lodha World School - Vision & Mission",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/inspireuscurriculum",
      },
      {
        property: "og:description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us! ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  values: {
    title:
      "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      {
        property: "og:title",
        content:
          "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/values",
      },
      {
        property: "og:description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  people: {
    title: "Meet the Leaders Behind Lodha World School's Vision",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      {
        property: "og:title",
        content: "Meet the Leaders Behind Lodha World School's Vision",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/people",
      },
      {
        property: "og:description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  inspireUsCurriculum: {
    title: "Lodha World School Custom Curriculum - Inspire Us",
    metaTags: [
      {
        name: "description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum, specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      {
        property: "og:title",
        content: "Lodha World School Custom Curriculum - Inspire Us",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  results: {
    title: "Lodha World Schools - Results",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      {
        property: "og:title",
        content: "Lodha World Schools - Results",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/results",
      },
      {
        property: "og:description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  skillDevelopment: {
    title: "Lodha World School - Skill Development Beyond the Classroom",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Skill Development Beyond the Classroom",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/skilldevelopent",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  leadershipDevelopment: {
    title:
      "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      {
        property: "og:title",
        content:
          "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/leadershipdevelopment",
      },
      {
        property: "og:description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  awards: {
    title: "Lodha World School - Awards and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Awards and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/awards",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  testimonials: {
    title: "Lodha World School - Testimonials From Parents & Students",
    metaTags: [
      {
        name: "description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Testimonials From Parents & Students",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/testimonials",
      },
      {
        property: "og:description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  staff: {
    title:
      "Lodha World School - Career Opportunities & Benefits | Join Our Team",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Career Opportunities & Benefits | Join Our Team",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/career",
      },
      {
        property: "og:description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  alumni: {
    title: "Lodha World School - Alumni: Forever Part Of Our Community",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Alumni: Forever Part Of Our Community",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/alumni",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  lwsMarkers: {
    title: "Lodha World School - Markers",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Markers",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lwsmarkers",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
};

export const lightBgWebCbg = {
  imageSource: "/assets/bg8.gif",
};

export const sidebarCbgPalava = {
  fb: "https://www.facebook.com/lodhaworldschoolmumbai/", // cbg link TODO: phone link to be added
  linkedin: "https://www.linkedin.com/company/lodha-world-school/",
  insta: "https://instagram.com/lodhaworldschool?igshid=YmMyMTA2M2Y=",
  email: "mailto:admission_palava@lodhaworldschool.com",
  admission: "mailto:admissions@lodhaworldschool.com",
  emailname: "admission_palava@lodhaworldschool.com",
  admissionname: "admissions@lodhaworldschool.com",
  phone: "tel:0251-6699588",
  phonename: "0251 6699588 / 6699589",
};
export const sidebarlinkCbgPalava = [
  {
    logo: "/assets/board2.png",
    link: "https://cisce-gov.in/",
    width: "80px",
  },
];

export const websiteSchemaCbgPalava = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Lodha World School, CBG, Palava",
  url: "https://www.lodhaworldschool.com/cbgpalava",
  logo: "https://www.lodhaworldschool.com/assets/logo.svg",
};
export const personSchemaCbgPalava = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  accountablePerson: [
    {
      "@type": "Person",
      name: "Ms. Aditi Banerji",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people8.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Priya Venkatesh",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people11.png",
      jobTitle: "Vice-Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Shruti Joshi",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people16.png",
      jobTitle: "Pre-Primary Academic Head",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Kavita Bhagwat",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people15.png",
      jobTitle: "Pre-Primary Coordinator",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Siba Nancy Peter",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people14.png",
      jobTitle: "Primary Coordinator",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Meenakshi Dehankar",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people13.png",
      jobTitle: "Secondary Coordinator",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Mala Chanda",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people12.png",
      jobTitle: "ISC Coordinator",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, CBG, Palava",
      },
    },
  ],
};

export const lightBgMobileCbg = {
  imageSource: "/assets/bg8mobile.gif",
};

export const landingCbgPalava = {
  imageSource: "/assets/palava.jpg",
  title1: "CBG Lodha World School, Palava -",
  title2: "Excellence within and beyond the classroom",
};

export const inspireUsCbgPalava = {
  title1: "Foundation &",
  title2: "Middle Years",
};

export const academicsCbgPalava = {
  imageSource: "/assets/academicsImg.png",
  alt: "Academic Excellence - CBG Lodha World School, Palava",
  imgTitle: "Academic Excellence at CBG Lodha World School, Palava",
  title: "Class of 2024 Results",
  name: "The Class of 2024 Results are a testament to our relentless drive towards pursuing academic excellence.",
  cta: "Class of 2024 Results",
};

export const joinLwsCbgPalava = {
  title: "Pursue Excellence at LWS Palava",
  imageSource: "/assets/students.jpg",
};

export const valuesCbgPalava = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
  {
    id: "houses-2",
    title: "Advaita:",
    color: "#276440",
    subtitle: "the one and only one.",
    description: "A leader has an unchanging, singular vision of the goal.",
    quote:
      "Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society",
  },
  {
    id: "houses-3",
    title: "Ananta:",
    color: "#034667",
    subtitle: "the infinite.",
    description: "A leader embodies limitless possibilities and energy.",
    quote:
      "Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden",
  },
  {
    id: "houses-4",
    title: "Akshaya:",
    color: "#CF9832",
    subtitle: "the undiminishing, inexhaustible spirit.",
    description:
      "A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
    quote:
      "Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom",
  },
];

export const chairpersonMsgCbgPalava = {
  //imageSource: "/assets/people11.png",
  imageSource: "/assets/cbgpalava_Principal_1019px-X-683px.jpg",
  alt: "CBG Lodha World School, Palava - Principal's Message",
  imgTitle: "Principal's Message about CBG Lodha World School, Palava",
  name: "Ms. Priya Venkatesh",
  title:
    "Let us work together to create a bright, hopeful future filled with endless possibilities and shape the leaders, thinkers, and change-makers of tomorrow.",
  nameMain: "-Ms. Priya Venkatesh",
  cta: "Principal’s Message",
  dialogText1:
    "It is my belief that at Lodha World School, CBG Palava, we are not just educators; we are the architects of dreams and are building a community where each child has the opportunity to discover their unique skills and talents.",
  dialogText2:
    "As we journey forward together, the words of Helen Keller assume special significance: 'Alone we can do so little; together we can do so much'.",
  dialogText3:
    "Together let us embrace the beauty of our collective dreams and aspirations, and work to create a bright, hopeful future filled with endless possibilities and shape the leaders, thinkers, and change-makers of tomorrow.",
  dialogText4:
    "Let us dare to dream big, think outside the box, and light the way to a brighter future for generations to come. I am deeply honoured and humbled to serve as Principal and am committed to serving the school community with utmost integrity and passion.",
  dialogText5:
    "I am deeply honoured and humbled to serve as Principal and am committed to serving the school community with utmost integrity and passion.",
};

export const middleYearsCbgPalava = [
  {
    imageSource: "/assets/inspireUsImg.png",
    alt: "Foundation & Middle Years - CBG Lodha World School, Palava",
    imgTitle: "Foundation & Middle Years at CBG Lodha World School, Palava",
    name: "Foundation & Middle Years",
    title:
      "At LWS, we redefine conventional learning. Our globally researched curriculum is crafted to deliver academic excellence.",
    cta: "Inspire Us Curriculum",
  },
];

export const seniorYearsCbgPalava = {
  imageSource: "/assets/academicsImgCbg.png",
  alt: "Senior Years - CBG Lodha World School, Palava",
  imgTitle: "Senior Years at CBG Lodha World School, Palava",
  name: "Senior Years",
  title:
    "We are affiliated to the Council for the Indian School Certificate Examinations (ICSE) and train our young leaders in keeping with its rigorous standards.",
  cta: "ICSE Curriculum",
};
export const seniorpara1CbgPalava = {
  para1:
    "The Council for the Indian School Certificate Examinations (CISCE) is committed to serving the nation’s children through high-quality educational endeavours. Through its practical-knowledge approach, it aims to empower learners to contribute towards a humane, just and pluralistic society and creates exciting learning opportunities with a commitment to excellence.",
  para2:
    "The CISCE follows a minimum-monitoring policy, giving freedom to experiment with new ideas and practices and allowing schools to evolve their own niche. It upholds a sense of ‘Indian Ethos’ - strong roots in the national psyche and sensitivity to national aspirations.",
  title1: "Best practices followed at LWS Palava",
  title2: "Subjects offered - ICSE Curriculum:",
  title3: "Subjects offered - ISC Curriculum:",
};

export const seniorpara2CbgPalava = [
  {
    name: "Gamut of interdisciplinary subjects offered to learners (mix of subjects across streams)",
  },
  {
    name: "In-depth focus on English Literature and Language",
  },
  {
    name: "Community Service as USP of the school",
  },
  {
    name: "Regular career guidance sessions with professionals from different walks of life",
  },
  {
    name: "Inter-school sports and cultural events spearheaded by students",
  },
];

export const seniorpara3CbgPalava = [
  {
    name: "English",
  },
  {
    name: "Hindi",
  },
  {
    name: "History",
  },
  {
    name: "Geography",
  },
  {
    name: "Physics",
  },
  {
    name: "Chemistry",
  },
  {
    name: "Biology",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Environmental Science",
  },
  {
    name: "Commercial Studies",
  },
  {
    name: "Computer Application / Commercial Application / Economic Application / Environmental Application / Art",
  },
];

export const seniorpara4CbgPalava = [
  {
    name: "English",
  },
  {
    name: "Science",
  },
  {
    name: "Physics",
  },
  {
    name: "Chemistry",
  },
  {
    name: "Biology",
  },
  {
    name: "Computer Studies",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Commerce",
  },
  {
    name: "Accounts",
  },
  {
    name: "Business Studies",
  },
  {
    name: "Economics",
  },
  {
    name: "Humanities",
  },
  {
    name: "Political Science",
  },
  {
    name: "Psychology",
  },
  {
    name: "Art",
  },
  {
    name: "Geography",
  },
  {
    name: "Physical Education",
  },
];

export const ourstoryCbgPalava = [
  {
    name: "The Lodha World School journey began in the heart of Thane. In 2008, the first Lodha World School was established - a warm, close-knit community of 24 eager learners. The vision of our school is to facilitate the holistic development of the young learners who walk into our doors. We endeavour to give back to the community by shaping and nurturing these young minds to become strong-minded and spirited leaders of tomorrow. Today, we have a widespread community, comprising 1000+ members, all united in their commitment to the school’s vision.",
  },
];

export const visionCbgPalava = [
  {
    title:
      "To nurture individuals sound of body, mind and spirit, and equip them to emerge as Leaders of Tomorrow.",
  },
];

export const missionCbgPalava = [
  {
    id: "vslideCbgPalava-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslideCbgPalava-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslideCbgPalava-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslideCbgPalava-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslideCbgPalava-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];

export const peopleCbgPalava = [
  {
    imageSource: "/assets/people11.png",
    name: "Ms. Priya Venkatesh",
    title: "Principal",
  },
  {
    imageSource: "/assets/people16.png",
    name: "Ms. Shruti Joshi",
    title: "Pre-Primary Academic Head",
  },
  {
    imageSource: "/assets/people15.png",
    name: "Ms. Kavita Bhagwat",
    title: "Pre-Primary Coordinator",
  },
  {
    imageSource: "/assets/people14.png",
    name: "Ms. Siba Nancy Peter",
    title: "Primary Coordinator",
  },
  {
    imageSource: "/assets/people13.png",
    name: "Ms. Meenakshi Dehankar",
    title: "Secondary Coordinator",
  },
  {
    imageSource: "/assets/people12.png",
    name: "Ms. Mala Chanda",
    title: "ISC Coordinator",
  },
];

export const testimonialsCbgPalava = [
  {
    name1: "Arunima Raghunath",
    name2: "parent of Anandita Vashisht",
    title:
      "Lodha World School is the best for my child as it offers an engaging and interactive learning environment and has excellent teachers and facilities. This helps my child develop out-of-box thinking and practically apply the learnt concepts and theory, which is so important in higher grades. The co-curricular and extracurricular activities help develop her personality. As a parent, I am very happy and grateful.",
  },
  {
    name1: "Anamica Basu",
    name2: "parent of Somili Basu",
    title:
      "Our partnership with Lodha World School, Palava started in 2014. The apprehensions of enrolling our children into a new school soon transformed into confidence as we realised they were in safe hands. Under the tutelage of an inspired Principal and well-informed teachers, our children have grown into confident individuals. A remarkably commendable effort was made by the school during the lockdown - they were quick to adapt to the digital delivery of pedagogy while keeping the personal touch intact. The school premises are safe and secure; adequate number of female security personnel and CCTV cameras at key inspection points infuse a sense of security among parents. The school also offers numerous opportunities to enhance one’s skill set through after-school or extracurricular activities. Overall, it has been a wonderful and a very reassuring experience.",
  },
  {
    name1: "Sangeeta Sajith",
    name2: "Parent of Gauri Sajith",
    title:
      "I am grateful to Lodha World School, Palava for nurturing my children and identifying their unique potential at every phase of life. Apart from academics, both of them have learnt valuable leadership skills as the school slogan rightly says “Leaders of tomorrow”! Teachers and the staff are supportive and the school brings about the holistic development of the child.",
  },
];

export const resultsCbgPalava = [
  {
    id: "piechart-1",
    title: "ISC Class of 2022 - CBG Palava",
  },
  {
    id: "piechart-2",
    title: "ICSE Class of 2023 - CBG Palava",
  },
];

export const starAwardsCbgPalava = [
  {
    imageSource: "/assets/awards10.png",
    name: "International School Award 2020-23, British Council",
  },
  {
    imageSource: "/assets/awards.png",
    name: "#17 in Mumbai, Times School Survey 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "#1 in Kalyan, EducationWorld India Rankings 2023-24",
  },
  {
    imageSource: "/assets/awards.png",
    name: "#19 in Maharashtra, EducationWorld India Rankings 2023-24",
  },
];

export const homeAwardsCbgPalava = {
  imageSource: "/assets/starawards.png",
  id: "awardsCbg",
};

export const skillachievementsCbgPalava = [
  {
    imageSource: "/assets/achieve1.png",
    name: "CISCE Swimming Championship 22-23",
    title: "Ashvin Kumar",
    title1: "[U-17 Boys, Regional Level]",
  },
  {
    imageSource: "/assets/achieve1.png",
    name: "CISCE Swimming Championship 22-23",
    title: "Shrinithi Kumar",
    title1: "[U-10 Girls, Regional Level]",
  },
  {
    imageSource: "/assets/achieve10.png",
    name: "CISCE Yoga Competition",
    title: "U-17 Boys",
    title1: "[Regional Level]",
  },
  {
    imageSource: "/assets/achieve10.png",
    name: "CISCE Yoga Competition",
    title: "Sanskruti Bagal (Runner up)",
    title1: "[U-19 Girls, National Level]",
  },
  {
    imageSource: "/assets/achieve2.png",
    name: "Archery Championship 2022",
    title: "Sharaya Saitawadekar",
    title1: "[U-9, District Level]",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "Guinness World Records",
    title: "Aarnav Dayavaan (Class 8)",
    title1: "Geet Sofat (Class 5)",
  },
];

export const leadershipachievementsCbgPalava = [
  {
    imageSource: "/assets/a1.png",
    name: "Ann Vig, Class XII, Aditya Rana, Class X",
    title: "IIMUN 2021",
    title1: "[International]",
  },
  {
    imageSource: "/assets/a2.png",
    name: "Rank 3: Neil Vishalchandra Ramteke",
    title: "[International]",
    title1: "",
  },
  {
    imageSource: "/assets/a3.png",
    name: "Rank 3: Pritish Mathur",
    title: "[International]",
    title1: "",
  },
  {
    imageSource: "/assets/a4.png",
    name: "Rank 3: Swastika Bindu",
    title: "[International]",
    title1: "",
  },
  {
    imageSource: "/assets/a5.png",
    name: "Rank 1: Gitali Garg",
    title: "[International]",
    title1: "",
  },
  {
    imageSource: "/assets/a6.png",
    name: "2nd position: Vihaan Gunjan Bhatt",
    title: "[National]",
    title1: "",
  },
  {
    imageSource: "/assets/a7.png",
    name: "Lodha World School",
    title: "IIMUN 2022",
    title1: "[Zonal]",
  },
  {
    imageSource: "/assets/a8.png",
    name: "Ann Vig, Class XII",
    title: "MUN 2021",
    title1: "[Inter-School]",
  },
  {
    imageSource: "/assets/a9.png",
    name: "2nd Prize: Rijul Rakheja",
    title: "Euro Eloquence",
    title1: "[Inter-School]",
  },
  {
    imageSource: "/assets/a10.png",
    name: "1st: Sinchana Bategari (Seniors), 2nd: Nishtha Tripathi (Seniors), 3rd: Shravari Chavan (Juniors)",
    title: "",
    title1: "",
  },
];

export const inspireCbgPalava = [
  {
    id: "inspireUsCbgPalava-1",
    title: "Learner-centric",
    description:
      "Following the Montessori Methodology, our curriculum presupposes that each learner has a unique individual promise and unique abilities, thus encouraging personalised learning.",
  },
  {
    id: "inspireUsCbgPalava-2",
    title: "Inclusive",
    description:
      "The instructional approach and content, learning objectives, and pace of learning are optimised according to each learner’s needs, ensuring no learner is left behind.",
  },
  {
    id: "inspireUsCbgPalava-3",
    title: "Holistic",
    description:
      "Learning activities are meaningful and relevant to both the academic and personal lives of learners, making learning holistic.",
  },
  {
    id: "inspireUsCbgPalava-4",
    title: "Skill-based",
    description:
      "The curriculum is designed to build artistic creativity, design thinking, and innovation. It is also deeply entrenched in our Four Markers, which helps train and empower our learners.",
  },
];

export const skillCbgPalava = [
  {
    id: "skillCbgPalava-1",
    title: "Sports",
  },
  {
    id: "skillCbgPalava-2",
    title: "Indoor games",
  },
  {
    id: "skillCbgPalava-3",
    title: "Music",
  },
  {
    id: "skillCbgPalava-4",
    title: "Dance",
  },
  {
    id: "skillCbgPalava-5",
    title: "Drama & Theatre",
  },
  {
    id: "skillCbgPalava-6",
    title: "Art",
  },
];
export const leadersCbgPalava = [
  {
    imageSource: "/assets/leadercbg.png",
    section1: "Prefects",
    section2: "ISC",
  },
];

export const prefectCbgPalava = [
  {
    position: "Head Boy: ",
    name: "Aron Mathew",
  },
  {
    position: "Head Girl: ",
    name: "Gauri Sajith",
  },
  {
    position: "Literary Head: ",
    name: "Anand Dutta",
  },
  {
    position: "Sports Captain: ",
    name: "Ashvin S",
  },
  {
    position: "Sports Vice-Captain: ",
    name: "Ankith G",
  },
];

export const captainCbgPalava = [
  {
    position: "Head Boy: ",
    name: "Tanmay Ahuja",
  },
  {
    position: "Head Girl: ",
    name: "Aaryahi Shetkar",
  },
  {
    position: "Cultural Head: ",
    name: "Kshipra Naik",
  },
  {
    position: "Literary Head: ",
    name: "Ayesha Abdullah",
  },
  {
    position: "Sports Captain: ",
    name: "Eashan Sartape",
  },
  {
    position: "Prefect (Humanities): ",
    name: "Liesha Bajaj",
  },
  {
    position: "Prefect (Science): ",
    name: "Prishita Dharampal",
  },
  {
    position: "Prefect (Commerce): ",
    name: "Varun Keswani",
  },
];

export const eventsCbgPalava = [];

export const headsCbgPalava = [
  {
    position: "",
    name: "Pranavi Kulkarni",
  },
  {
    position: "",
    name: "Snigdha Somwanshi",
  },
  {
    position: "",
    name: "Akshat Singh",
  },
  {
    position: "",
    name: "Anika Singh",
  },
  {
    position: "",
    name: "Sarayu Sonthenham",
  },
  {
    position: "",
    name: "Angela Anthony",
  },
  {
    position: "",
    name: "Sanaya Kirilkar",
  },
];

export const leadershipCbgPalava = [
  {
    id: "leadershipCbgPalava-1",
    title: "Community Outreach",
  },
  {
    id: "leadershipCbgPalava-2",
    title: "Student Council - ICSE",
  },
  {
    id: "leadershipCbgPalava-3",
    title: "Student Council - ISC",
  },
  {
    id: "leadershipCbgPalava-4",
    title: "Competitions",
  },
];

export const schoolCbgPalava = [
  {
    img: "/assets/cbgPalava.png",
    name: "CBG, Palava",
    email: "careers_palava@lodhaworldschool.com",
    phone: "0251-669-9588",
    cta: "CBG Palava",
    ctalink: "https://www.lodhaworldschool.com/palava/",
  },
];

export const applytoCbgPalava = [
  {
    link: "#",
    name: "Physics Teacher - Grades 6-8",
  },
  {
    link: "#",
    name: "Hindi Teacher - Grades 6-8",
  },
  {
    link: "#",
    name: "History Teacher - Grades 8-10",
  },
  {
    link: "#",
    name: "Biology Teacher - Grades 6-8",
  },
  {
    link: "#",
    name: "Admin Manager",
  },
  {
    link: "#",
    name: "Lab Assistant",
  },
];

export const storyCbgPalava = [
  {
    text: "In 2008, the first Lodha World School was established in Thane - a warm, close-knit community of 24 eager learners. The second Lodha World School was set up in June 2013 in the first phase of Palava.",
  },
  {
    text: "The vision of our school is to stimulate inquisitiveness, independence and a life-long love of learning, enabling our learners to become contributing members of our community.",
  },
  {
    text: "While academic excellence is our central goal, learning at LWS Palava goes beyond the classroom. We have a wealth of activities which encourage our pupils to fulfil their potential and develop a diverse range of skills, as part of the regular timetable.",
  },
  {
    text: "We are affiliated to the CISCE (Council for the Indian School Certificate Examinations) and offer the ICSE and ISC examinations for our Class 10 and Class 12 learners respectively.",
  },
];

export const markersCbgPalava = [
  {
    id: "symbioticCbg-1",
  },
  {
    id: "symbioticCbg-2",
  },
  {
    id: "symbioticCbg-3",
  },
];

// need to be updated to designCbg-1 once received images
export const designCbgPalava = [
  {
    id: "design-1",
  },
  {
    id: "design-2",
  },
  {
    id: "design-3",
  },
];

export const environmentCbgPalava = [
  {
    id: "environmentCbg-1",
  },
  {
    id: "environmentCbg-2",
  },
  {
    id: "environmentCbg-3",
  },
];

export const wellnessCbgPalava = [
  {
    id: "wellnessCbg-1",
  },
  {
    id: "wellnessCbg-2",
  },
  {
    id: "wellnessCbg-3",
  },
];

export const infraCbgPalava = [
  {
    id: "infraCbg-1",
    title: "Library",
  },
  {
    id: "infraCbg-2",
    title: "Laboratory",
  },
  {
    id: "infraCbg-3",
    title: "Football Ground",
  },
  {
    id: "infraCbg-4",
    title: "Dance Room",
  },
  {
    id: "infraCbg-5",
    title: "Music Room",
  },
  {
    id: "infraCbg-6",
    title: "Preschool Activity Room",
  },
  {
    id: "infraCbg-7",
    title: "Corridor",
  },
];

export const infraDataCbgPalava = {
  para1:
    "The environment at the school is designed to enable students to excel and flourish - not just in academics, but in activities and events beyond the classroom, too. Lodha World School, Palava is well-equipped with state-of-the-art infrastructure and facilities across all its learning spaces.",
};
